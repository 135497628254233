unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls;

type
  TForm1 = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.WebButton1Click(Sender: TObject);
begin
  ShowMessage('Hello World!');
end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create('idTest');
  WebButton1 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 640;
    Height := 480;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 125;
    WebLabel1.Top := 27;
    WebLabel1.Width := 66;
    WebLabel1.Height := 17;
    WebLabel1.Margins.Left := 3;
    WebLabel1.Margins.Top := 3;
    WebLabel1.Margins.Right := 3;
    WebLabel1.Margins.Bottom := 3;
    WebLabel1.Caption := 'Hello World!';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 29;
    WebPanel1.Top := 83;
    WebPanel1.Width := 275;
    WebPanel1.Height := 190;
    WebPanel1.Margins.Left := 3;
    WebPanel1.Margins.Top := 3;
    WebPanel1.Margins.Right := 3;
    WebPanel1.Margins.Bottom := 3;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 1;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 85;
    WebButton1.Top := 37;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Margins.Left := 3;
    WebButton1.Margins.Top := 3;
    WebButton1.Margins.Right := 3;
    WebButton1.Margins.Bottom := 3;
    WebButton1.Caption := 'Press Me!';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.